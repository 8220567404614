import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const applicationInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.NEXT_PUBLIC_APPINSIGHTS_CONNECTION_STRING,
        enableCorsCorrelation: true
    }
});

if (typeof window !== 'undefined') {
    applicationInsights.loadAppInsights();
}

export default applicationInsights;